/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $(document).ready(function() {
    	// Dropdown menu hover effect
		//use the breakpoint for menubar collapsing
		if ($(window).width() > 767) {
		    //check out https://github.com/briancherne/jquery-hoverIntent/issues/6 to help with the adjacent menu items delay
		    $('.content-wrap li.dropdown').hoverIntent({
		        timeout: 400,
		        sensitivity: 2,
                over:function() {
			        $(this).find('.dropdown-menu:first').stop(true, true).delay(200).fadeIn(500);
			        $(this).addClass("open");
			    },
			    out: function() {
                    $(this).find('.dropdown-menu:first').stop(true, true).delay(500).fadeOut(500);
                    $(this).removeClass("open");
                }
			});
		
			//add .dropdown-menu-right to menu items on right side of menu to fix off page overflow
			var $items = $('ul#menu-main-menu > li');
			for (var i = Math.ceil($items.size()/2);i < $items.size(); i++) {
				$($items[i]).find('.dropdown-menu').addClass('dropdown-menu-right');
			}
		}
		/* remove hover menu the second they touch the screen
		window.addEventListener('touchstart', function() {
          // the user touched the screen!
        }, false);
        */
	
		//Video Popup Overlay
		var $body = $('body');
		
		$('#video-overlay-player-wrapper').click(function (e) {
	           e.stopPropagation();
	   	});

		$body.on('click', '#video-overlay', function (e) {
		        $('#video-overlay').fadeOut();
		        $('#video-overlay-player-wrapper').html('');
		        return false;
		});
		
		$body.on('click', '.watch-video-link', function (e) {
				var href = $(this).attr('href'),
				$id;
				//console.log(this);
				if (href.indexOf('vimeo') === -1) { return true; }
				e.preventDefault();
				var match = /vimeo.*\/(\d+)/i.exec( href );
				//console.log(match[1]);
				if (match[1]) {
				    $id = match[1];
				}else{
				    $id = '';
				}
	            $('#video-overlay').fadeIn();
				var autoplay = true;
	            $('#video-overlay-player-wrapper').html('<iframe src="//player.vimeo.com/video/' + $id + '?autoplay=' + autoplay + '"  width="100%" height="100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
	            return false;
	    });
	    
	    $('#homepage-facts-slider').owlCarousel({
	        loop:false,
            margin:10,
            items: 4,
            nav:true,
            navText: ['',''],
            responsiveClass:true,
            navClass:['owl-prev owl-nav-btn-home','owl-next owl-nav-btn-home'],
	        responsive:{
                    0:{
                        items:1,
                        nav:true
                    },
                    600:{
                        items:3,
                        nav:true
                    },
                    1000:{
                        items:4,
                        nav:true,
                    }
                }
	    });
	    $('#homepage-testimonials-slider').owlCarousel({
	        loop: false,
	        items: 3,
	        nav: true,
	        margin: 40,
	        responsiveClass:true,
	        navText: ['',''],
	        navClass:['owl-prev owl-nav-btn-home','owl-next owl-nav-btn-home'],
	        responsive:{
                    0:{
                        items:1,
                        nav:true
                    },
                    700:{
                        items:2,
                        nav:true
                    },
                    1000:{
                        items:3,
                        nav:true,
                    }
                }
	    });
	}); //end $(document).ready()
	
	$(document).ready(function(){
		moment.locale('en');
		var feed_template = '<div class="social-feed-element" dt-create="{{=it.dt_create}}" social-feed-id="{{=it.id}}">' +
            '{{? it.attachment }}' +
            '<div class="feed-attachment">' +
            '<a href="{{=it.link}}" target="_blank">{{=it.attachment}}</a>' +
            '</div>' +
            '{{?}}' +
            '<div class="feed-content">' +
                '<a href="{{=it.link}}" target="_blank" class="read-button" style="float: right;"><i class="fa fa-icon-external-link-square"></i></a>' +
                '<div class="media-body">' +
                    '<a href="{{=it.link}}" target="_blank" class="read-button" style="float: right;"><i class="fa fa-external-link"></i></a>' +
                    '<div class="text-wrapper">' +
                        '<p class="social-feed-text">{{=it.text}}</p>' +
                    '</div>' +
                '</div>' +
            '</div>' +
            '<span class="muted pull-right facebook-time-ago"> {{=it.time_ago}}</span>' +
        '</div>';
        $('.social-feed-container').socialfeed({
            facebook:{
            	accounts:['@ChapelHillAcademy'],//usernames or hashtags
                limit:20,
                access_token:'240696342763428|FgHgjfn7wWMNT15ONHP0tVdWm_k'
			},
            // GENERAL SETTINGS
			show_media:true,
			media_min_width: 0,
            template_html: feed_template,
            length:400,
            callback: function(){
                
                //try this: http://gromo.github.io/jquery.scrollbar/demo/basic.html
              /*
              var noOfImages = $(".social-feed-container img").length;
              var noLoaded = 0;
              var timeoutX;
              $(".social-feed-container img").on('load', function(){ 
                  clearTimeout(timeoutX);
				  timeoutX = setTimeout(function() {
					$('.social-feed-container').css('overflow-y', 'auto').jScrollPane({
                        'verticalDragMinHeight' : 30,
                        'verticalDragMaxHeight' : 30
                      });
				}, 2000);

              });
              */
            }
		});
		$('.mega-toggle-blocks-center').remove();
		$('.mega-toggle-blocks-right').remove();
		$('#mega-toggle-block-2').remove();
		var $search = $('<a/>', {
		   class: "",
		   href: "/search/",
		   id: "mobile-search-button",
		   text: "Search"
		}).css("flex", "1");
		var $parents = $('<a/>', {
		   class: "",
		   href: "/parents-corner/",
		   id: "mobile-parents-button",
		   text: "Parents"
		});
		$('.mega-menu-toggle').append($parents).append($search);
	});
	
})(jQuery);

(function($) {
  	var $container = $('.offcanvas-top'),
		$cHeight = $('.o-content').outerHeight();
	function buildCanvas() {
		//$('<a href="#" id="trigger">More +</a>').appendTo($container);
        //#mobile-search-button
        
		$(document).on('click', '.menu-item-565, #mobile-search-button', function(e) {
			//console.log(e);
			e.preventDefault();
			if($container.hasClass('active')) {
				$container.toggleClass('active');
				$container.height(0);
			} else {
				$container.toggleClass('active');
				window.scrollTo(0, 0);
				$container.height(60);
				$container.find('#top-search-input').focus();
			}
		});
	}
	
	$(document).ready(function() {
		buildCanvas();
		$('.panel-collapse.in').parent().addClass('expanded');
	});

	$(window).resize(function() { //On Window resizeBy(
		$cHeight = $('.o-content').outerHeight();
    		//console.log($cHeight);
	});
	
})(jQuery);