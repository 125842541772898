(function($) {
        $.gmap3({
            key: 'AIzaSyB6fLHOGo7QbcUeda2pMW6BX85cKNBxy5A'
          });
        //map style
        var mapStyle = [
            {
                "featureType": "landscape.natural",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#403f3f"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "lightness": 100
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "lightness": 700
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#46bcec"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#73b1dd"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#003581"
                    }
                ]
            }
        ];
        
        $(document).ready(function () {
                //load map (on contact page)
                var mapSingle = $('#cha-map');
                if (mapSingle.length > 0) {
                    var center = [44.862872, -93.530173];
                    var cha_icon = {url: '/wp-content/themes/cha2017/dist/images/map-marker-hi.png',
                        size: new google.maps.Size(39,61),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(19.5, 61),
                        scaledSize: new google.maps.Size(39,61)
                    };
                    mapSingle.gmap3({
                                    //action: 'setStyledMap',
                                    center: center,
                                    zoom:11,
                                    scrollwheel: false,
                                    // implicit init - - - - -
                                    // - - - - - - - - - - - -
                                    /*
                                    mapTypeId: "gray",
                                    styledmap: {
                                            id: 'gray',
                                            style: mapStyle,
                                            options: {
                                                    name: 'Map'
                                            }
                                    }
                                    */
                            }

                    )
                    .marker({
                        position: center,
                    
                        //anchor: new google.maps.Point(22, 0),
                        //scaledSize: new google.maps.Size(10,50),
                        //icon: new google.maps.MarkerImage('/wp-content/themes/cha2017/dist/images/map-marker-hi.png')
                        icon: cha_icon
                        //https://developers.google.com/maps/documentation/javascript/examples/place-details
                        //place: google.maps.MarkerPlace(center, "ChIJPXUbjY8b9ocRUbFmDaHITfk")
                    })
                    .infowindow({
                        content: "<strong>Chapel Hill Academy</strong><br>306 W. 78th Street<br>Chanhassen, MN 55317<br><a href='https://www.google.com/maps/place/Chapel+Hill+Academy/@44.8628519,-93.5323667,17z/data=!3m1!4b1!4m5!3m4!1s0x87f61b8f8d1b753d:0xf94dc8a10d66b151!8m2!3d44.8628519!4d-93.530178' target='_blank'>Get Directions</a>"
                    })
                    .then(function (infowindow) {
                        var map = this.get(0);
                        var marker = this.get(1);
                        marker.addListener('click', function() {
                            infowindow.open(map, marker);
                        });
                    })
                    .styledmaptype(
                        "gray", 
                        mapStyle,
                        {name: "Gray"}
                    );
                } //end if
        });
})(jQuery);